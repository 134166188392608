import './App.css';
import Questions from './components/Questions.js';
import PostQuestion from './components/PostQuestion.js';
import { useEffect, useState } from 'react';
import { fetchFromUrl } from './Global/urlFunctions.js';

function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [notification, setNotification] = useState('');

  const getUser = () => {
    const fetchData = async () => {
      const email = localStorage.getItem('email');
      const password = localStorage.getItem('password');
      const userId = localStorage.getItem('userId');
  
      if (email && password && userId) {
        const userFromAPI = await fetchFromUrl('/user/get_user', { method: 'GET' }, { email }, true);
        const passwordCorrect = await fetchFromUrl('/user/check_password_equality', { method: 'POST' }, { user_id: userId, password }, true);
  
        if (userFromAPI && passwordCorrect) setUser(userFromAPI);
      }
  
      return true;
    };

    fetchData();
  };

  const signUp = async () => {
    const email = document.getElementById('email_input').value;
    const password = document.getElementById('password_input').value;

    const userFromAPI = await fetchFromUrl('/user/get_user', { method: 'GET' }, { email, password }, true);

    if (!userFromAPI) {
      setNotification('Wrong email');

      return;
    }

    const passwordCorrect = await fetchFromUrl('/user/check_password_equality', { method: 'POST' }, { user_id: userFromAPI.id, password }, true);

    if (passwordCorrect) {
      localStorage.setItem('email', email);
      localStorage.setItem('password', password);
      localStorage.setItem('userId', userFromAPI.id);

      setNotification('Successfully logged in. Please reload to continue.');
    } else {
      setNotification('Wrong password');
    }
  };

  const logOut = () => {
    localStorage.removeItem('email');
    localStorage.removeItem('password');
    localStorage.removeItem('userId');

    window.location.reload();
  };

  const onLoad = () => {
    getUser();
    setTimeout(() => setLoading(false), 10000);
  };

  useEffect(onLoad, []);

  return (
    <>
      {loading && (
        <div className='loading-screen'>
          <div><i className="fa-solid fa-spin fa-spinner"></i></div>
        </div>
      )}
      {!loading && (
        <>
          <h1>Question Merchant</h1>
          {user && (
            <>
              Sell questions here. For careers or inquires, please contact Thomas Smith.

              <br />
              <br />

              <PostQuestion user={user} />

              <button onClick={logOut}>Log out</button>
              <br />
              <br />

              <Questions setLoading={setLoading} user={user} />
            </>
          )}
          {!user && (
            <>
              <h2>Log in with NextCoin</h2>
              <b>Email: </b><input id="email_input" /><br />
              <b>Password: </b><input id="password_input" type="password" /><br />
              <i>Your billing data is not held on a server.</i><br />
              <button onClick={signUp}>Log in</button>
              <br />
              {notification}
            </>
          )}
        </>
      )}
    </>
  );
}

export default App;
