import { fetchFromUrl } from "../Global/urlFunctions";

const PostQuestion = (props) => {
    const { user } = props;

    const postQuestionToAPI = async () => {
        const question = document.getElementById('question_input').value;
        const price = document.getElementById('price_input').value;

        await fetchFromUrl('/questions/create', { method: 'POST' }, { question, price, author: user.email });

        window.location.reload();
    };

    return (
        <>
            <b>Question:</b> <input id="question_input" placeholder="Question Here..." /><br />
            <b>Price:</b> <input id="price_input" type="number" />&nbsp;nc<br />
            <button onClick={postQuestionToAPI}>Post Question</button>
            <br />
            <br />
        </>
    );
};

export default PostQuestion;
