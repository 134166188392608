const toQueryString = (params = {}) => {
    const qsArray = [];
    let str = '';
    Object.keys(params).forEach((key) => {
      // Do not include value if value is false
      if (params[key] || params[key] === 0) qsArray.push(`${key}=${params[key]}`);
    });
    if (qsArray.length) {
      const qs = qsArray.join('&');
      str += `?${qs}`;
    }
    return str;
};

const fetchFromUrl = async (url, options, params, nextCoin = false) => {
    const endpoint = nextCoin ? 'https://pct4k7-3000.csb.app' : 'https://ryhff6-3000.csb.app';

    const resp = await fetch(`${endpoint}/${url}${toQueryString(params)}`, options);

    return resp.json();
};

export {
    fetchFromUrl
};
