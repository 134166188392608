import { useEffect, useState } from "react";
import { styled } from 'styled-components';
import { getQuestions } from '../Global/Questions.js';
import { fetchFromUrl } from "../Global/urlFunctions.js";

const Table = styled.table`
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
`;

const ColumnHeader = styled.th`
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
`;

const Column = styled.td`
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
`;

const Row = styled.tr`
    &:nth-child(even) {
        background-color: #dddddd;
    }
`;

const Questions = (props) => {
    const { setLoading, user } = props;

    const [questions, setQuestions] = useState([]);
    const [areYouSure, setAreYouSure] = useState(null);
    const [recipientsWallets, setRecipientsWallets] = useState([]);

    useEffect(() => {
        const fetchData = async () => setQuestions((await getQuestions()));
        fetchData();
    }, []);

    const buyQuestion = async (question) => {
        const userWalletId = document.getElementById('user_wallet_choice').value;
        const authorWalletId = document.getElementById('author_wallet_choice').value;

        setLoading(true);

        const resp = await fetchFromUrl('/wallet/transfer', { method: 'POST' }, {
            recipient_wallet_id: authorWalletId,
            owner_user_email: localStorage.getItem('email'),
            owner_user_password: localStorage.getItem('password'),
            owner_wallet_id: userWalletId,
            coin_amount: question.price
        }, true);

        if (resp.success) await fetchFromUrl('/questions/update', { method: 'POST' }, { id: question.id, bought_by: user.email });

        window.location.reload();
    };

    return (
        <>
            <Table>
                <thead>
                    <Row>
                        <ColumnHeader>Question</ColumnHeader>
                        <ColumnHeader>Price</ColumnHeader>
                        <ColumnHeader>Author</ColumnHeader>
                        <ColumnHeader>Purchase</ColumnHeader>
                    </Row>
                </thead>
                <tbody>
                    {questions.map((question) => (
                        <Row key={`question_${question.id}`}>
                            {!question.bought_by && (
                                <>
                                    <Column>{question.question}</Column>
                                    <Column>{question.price}nc</Column>
                                    <Column>{question.author}</Column>
                                    <Column>
                                        <button
                                            purchase_button="true"
                                            onClick={async () => {
                                                setAreYouSure({ question, loading: true });
                                                Array.from(document.querySelectorAll('[purchase_button="true"]')).map((btn) => btn.setAttribute('disabled', 'true'));

                                                const authorUser = await fetchFromUrl('/user/get_user', { method: 'GET' }, { email: question.author }, true);
                                                setRecipientsWallets(authorUser.wallets);

                                                setAreYouSure({ question, loading: false });
                                            }}
                                        >
                                            Purchase
                                        </button>

                                        {areYouSure?.question?.id === question.id && (
                                            <>
                                                {areYouSure.loading && (
                                                    <>
                                                        &nbsp;
                                                        &nbsp;
                                                        <i className="fa-solid fa-spin fa-spinner"></i>
                                                    </>
                                                )}
                                                {!areYouSure.loading && (
                                                    <>
                                                        <select id="author_wallet_choice">
                                                            <option disabled selected>Recipient Wallets</option>
                                                            {recipientsWallets.map((wallet) => (<option value={wallet.id}>{wallet.name} : {wallet.coin}nc</option>))}
                                                        </select>

                                                        <select id="user_wallet_choice">
                                                            <option disabled selected>Your Wallets</option>
                                                            {user.wallets.map((wallet) => (<option value={wallet.id}>{wallet.name} : {wallet.coin}nc</option>))}
                                                        </select>

                                                        <button onClick={() => buyQuestion(question)}>Are you sure?</button>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Column>
                                </>
                            )}
                        </Row>
                    ))}
                </tbody>
            </Table>
        </>
    );
};

export default Questions;
